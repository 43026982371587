import classNames from "classnames"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Button from "../../../../common/button/button"
import Webfile from "../../../../common/display/webfile/webfile"
import CollectionMediaDisplay from "../../../../media-output/collection-media-display/collection-media-display"
import MediaIndividualDetails from "../media-individual-details/media-individual-details"
import styles from "./collection-wrapper.module.scss"
import GenericMessage from "../../../../common/generic-message/generic-message"

function CollectionWrapper({
  formData,
  submit,
  goBack,
  className,
  formWrappers,
  constants,
  location,
}) {
  // const [suggestedKeywords, updateKeywords] = useSuggestedKeywords()
  const [activeMedia, setActiveMedia] = useState(formData.medias[0])
  const [submitAttempted, setSubmitAttempted] = useState()
  const [error, setError] = useState()
  const [selectedGroup, setSelectedGroup] = useState()
  const [updatedGroups, setUpdatedGroups] = useState([])
  
  const updateAndSetActiveMedia = media => {
    activeMedia.active = false
    media.active = true
    setActiveMedia(media)
  }

  const getFormWrapper = mediaData => {
    var wrapper = null
    wrapper = _.find(formWrappers, { id: mediaData.id })
    if (!wrapper) {
      console.log(
        "no wrapper found. creating new one for media with id " + mediaData.id
      )
      wrapper = { media: mediaData, id: mediaData.id }
      formWrappers.push(wrapper)
    } else {
      console.log("Wrapper found for media with id " + mediaData.id)
    }
    return wrapper
  }

  const getMediaPreview = () => {
    if (!formData.medias) {
      return <div>Error: no valid media</div>
    } else if (formData.medias.length === 1) {
      return (
        <div className={styles.singlePreview}>
          <Webfile
            item={formData.medias[0]}
            type={formData.medias[0].mediaType}
          />
        </div>
      )
    } else {
      return (
        <CollectionMediaDisplay
          id={"CollectionMediaListStep04"}
          medias={formData.medias}
          activeMedia={activeMedia}
          setActiveFn={updateAndSetActiveMedia}
          formWrappers={formWrappers}
        ></CollectionMediaDisplay>
      )
    }
  }

  const runFormValidation = (v, instance) => {
    var _error = false
    if (!instance) {
      _error = "Form has errors" // generic error
    } else {
      let values = instance.values
      if (!values.title) {
        _error = "Title is required"
      } else if (!values.photoDate && !values.isDateUnknown) {
        _error = "Photo Date is required"
      } else if (
        (!formData.collectionKeywords ||
          formData.collectionKeywords.length === 0) &&
        (!values.keywords || values.keywords.length === 0)
      ) {
        _error = "At least one keyword is required"
      }
    }

    return _error
  }
  // currently we just check to see that fields are valid. If they are for every form then we can submit.
  const runCollectionValidation = () => {
    setSubmitAttempted(false)
    var _error = false

    var suffix = "."
    _.map(formWrappers, function(formWrapper, idx) {
      if (formWrappers.length > 1) {
        suffix = " for media (" + (idx + 1) + ")."
      }

      _error = runFormValidation(
        formWrapper.formInstance.values,
        formWrapper.formInstance
      )

      if (_error) {
        _error = _error + suffix
        return false // break out of loop
      }
    })

    setError(_error)
    return _error
  }

  const submitAllMedias = () => {
    var errors = runCollectionValidation()

    if (!errors) {
      submit() // this is handled in the upload wizard for this step. Should/could probably move into here now that it is finalized
    } else {
      setSubmitAttempted(true)
    }
  }

  const getGenericMessage = () => {
    if (formWrappers.length > 1 && submitAttempted) {
      // we only need to show this for batch upload. Other cases will validate directly on the form
      return <GenericMessage message={error}></GenericMessage>
    }
  }

  _.forEach(formData.medias, function(mediaData) {
    getFormWrapper(mediaData)
  })

  const updateGroups = (groups) => {
    console.log('updateGroups')
    setUpdatedGroups(groups)
  }

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.content}>
        <div className={styles.thumbnailWrap}>{getMediaPreview()}</div>
        <div className={styles.fieldWrap}>
          {_.map(formWrappers, (formWrapper, idx) => (
            <MediaIndividualDetails
              idx={idx}
              formWrapper={formWrapper}
              // constants={constants}
              setSelectedGroup={setSelectedGroup}
              selectedGroup={selectedGroup}
              updatedGroups={updatedGroups}
              fnUpdateGroups={updateGroups}
              location={location}
              collectionKeywords={
                formData.collectionKeywords ? formData.collectionKeywords : []
              }
              validateFn={runFormValidation}
            ></MediaIndividualDetails>
          ))}
        </div>
      </div>
      <div className={styles.action}>
        {getGenericMessage()}
        <div className={styles.buttonWrap}>
          <Button
            id="upload-media-detail-back"
            type="button"
            label="Previous"
            btnStyle="ghost"
            btnSize="large"
            onClick={event => {
              goBack()
            }}
          />
          <Button
            id="upload-media-detail-publish"
            type="button"
            label="Publish"
            btnStyle="primary"
            btnSize="large"
            onClick={submitAllMedias}
          />
        </div>
      </div>
    </div>
  )
}

CollectionWrapper.propTypes = {
  medias: PropTypes.object,
  formWrappers: PropTypes.array.isRequired,
  submit: PropTypes.func,
  goBack: PropTypes.func,
  className: PropTypes.string,
}

export default CollectionWrapper
