import axios from "axios"
import http from "http"
import _ from "lodash"

// TODO query server?
const permissions = [{
    value: "PUBLIC",
    label: "Anyone can view this media",
  },
  {
    value: "LOGGEDIN",
    label: "Only logged in members can view this media",
  },
  {
    value: "SHARED",
    label: "Only members of the AFPA can view this media",
  },
  {
    value: "PRIVATE",
    label: "Only members of my organization can view this media",
  },
]

// TODO query server?
const copyrights = [{
    value: "ANYONE",
    label: "Anyone can use this media",
  },
  {
    value: "PERSONAL",
    label: "This media can be used for personal or academic use",
  },
  {
    value: "ATTRIBUTE",
    label: "This media can be used with attribution",
  },
]

const searchDebounce = 500 //milliseconds

const searchPath = "/search"

const uploadPath = "/upload"

export const getPermissionOptions = (org) => {
  const updatedPermissions = _.map(permissions, (permission) => {

    let label = permission.label
    label = label.replace('my organization', org)
    const value = permission.value
    const updatedPermission = {
      value,
      label
    }

    return updatedPermission
  })

  return updatedPermissions
  // return new Promise(function(resolve, reject) {
  //   resolve(permissions)
  // })
}

export const getCopyrightOptions = () => {
  return copyrights
  // return new Promise(function(resolve, reject) {
  //   resolve(copyrights)
  // })
}

export default {
  getPermissionOptions,
  getCopyrightOptions,
  searchDebounce,
  searchPath,
  uploadPath,
}