import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./choose-media-type.module.scss"

function ChooseMediaType({ submit, className }) {
  const handleSubmit = type => {
    submit({ type: type })
  }

  return (
    <div className={classNames(styles.wrapper, className)}>
      <h2>What Media Type are you going to upload?</h2>
      <ul>
        <li id="choosePhoto" onClick={() => handleSubmit("Photo")}>
          <div className={styles.largeIcon}>
            <svg viewBox="0 0 55 55">
              <use xlinkHref="#large-icon-photo" />
            </svg>
          </div>
          <div className={styles.iconLabel}>Photo</div>
        </li>
        <li id="chooseVideo" onClick={() => handleSubmit("Video")}>
          <div className={styles.largeIcon}>
            <svg viewBox="0 0 55 55">
              <use xlinkHref="#large-icon-video" />
            </svg>
          </div>
          <div className={styles.iconLabel}>Video</div>
        </li>
        <li id="chooseAudio" onClick={() => handleSubmit("Audio")}>
          <div className={styles.largeIcon}>
            <svg viewBox="0 0 55 55">
              <use xlinkHref="#large-icon-music" />
            </svg>
          </div>
          <div className={styles.iconLabel}>Audio</div>
        </li>
        <li id="chooseDocument" onClick={() => handleSubmit("Document")}>
          <div className={styles.largeIcon}>
            <svg viewBox="0 0 55 55">
              <use xlinkHref="#large-icon-document" />
            </svg>
          </div>
          <div className={styles.iconLabel}>Document</div>
        </li>
        <li id="chooseArchive" onClick={() => handleSubmit("Archive")}>
          <div className={styles.largeIcon}>
            <svg viewBox="0 0 55 55">
              <use xlinkHref="#large-icon-files" />
            </svg>
          </div>
          <div className={styles.iconLabel}>File</div>
        </li>
      </ul>

      <svg display="none">
        <path
          id="large-icon-photo"
          d="M14811,8386.182a4.968,4.968,0,0,1-5-4.908v-34.364a4.968,4.968,0,0,1,5-4.908h45a4.968,4.968,0,0,1,5,4.908V8369a1.286,1.286,0,0,1-2.5,0v-22.09a2.485,2.485,0,0,0-2.5-2.454h-45a2.486,2.486,0,0,0-2.5,2.454v34.364a2.667,2.667,0,0,0,2.5,2.454h45a2.666,2.666,0,0,0,2.5-2.454v-4.666l-.749-.734-7-9.082s-2.5-2.946-5.251-2.7c-2.249.246-4.5,2.454-6.5,6.874a30.88,30.88,0,0,1-3.248,5.4,6.949,6.949,0,0,1-3.5,2.212c-2.5.734-4.748-.98-7.251-2.946l-.25-.246c-2.748-2.208-4-1.474-9.5,3.438a.95.95,0,0,1-1.5,0c-.25-.492-.25-1.227.25-1.473,2.748-2.7,4.751-4.174,6.5-4.908,2-.739,3.749-.493,6,1.227l.25.242c2,1.474,3.748,2.946,5.248,2.454s3.252-2.454,5-6.377c2.249-5.158,5-8.1,8.25-8.348a8.858,8.858,0,0,1,7.25,3.682l7.251,9.081,1.249,1.474v5.4a4.968,4.968,0,0,1-5,4.908Zm2.778-30.333a6.485,6.485,0,1,1,6.482,6.366A6.363,6.363,0,0,1,14813.779,8355.849Zm2.593,0a3.89,3.89,0,1,0,3.89-3.819A3.953,3.953,0,0,0,14816.372,8355.849Z"
          transform="translate(-14806 -8337)"
        />
        <path
          id="large-icon-video"
          d="M14304.983,7995.882h-41.03a3.955,3.955,0,0,1-3.952-3.952V7955.7a3.957,3.957,0,0,1,3.952-3.955h47.095a3.957,3.957,0,0,1,3.952,3.955v36.224a3.945,3.945,0,0,1-3.944,3.959Zm6.064-2.124a1.841,1.841,0,0,0,1.836-1.836v-3.043h-6.538v4.879Zm-7.426,0v-39.9h-32.232v39.9Zm-41.5-1.836a1.841,1.841,0,0,0,1.836,1.836h4.71v-5.809h-6.546Zm50.767-5.224v-3.941h-6.538v3.941Zm-44.221-.628v-3.4h-6.546v3.4Zm0-5.278v-3.4h-6.546v3.4Zm44.221-.215v-3.948h-6.538v3.948Zm-44.221-5.065v-3.4h-6.546v3.4Zm44.221-1.063V7970.5h-6.538v3.948Zm-44.221-4.21v-3.4h-6.546v3.4Zm44.221-1.918v-3.941h-6.538v3.941Zm-44.221-3.363v-3.4h-6.546v3.4Zm44.221-2.759v-3.944h-6.538v3.944Zm-44.221-2.522v-5.816h-4.71a1.842,1.842,0,0,0-1.836,1.839v3.977Zm44.221-3.6v-.374a1.842,1.842,0,0,0-1.836-1.839h-4.7v2.213Zm-30.817,25.945a1.374,1.374,0,0,1-.722-1.2v-14a1.362,1.362,0,0,1,.722-1.2,1.377,1.377,0,0,1,1.408.079l10.265,7a1.359,1.359,0,0,1,0,2.249l-10.265,7a1.345,1.345,0,0,1-1.408.079Zm2-3.783,6.485-4.422-6.485-4.423Z"
          transform="translate(-14260 -7946.79)"
        />
        <path
          id="large-icon-music"
          d="M180.306,129.269V167.19a7.331,7.331,0,1,1-2.528-5.536V141l-27.809,6.952v26.823a7.331,7.331,0,1,1-2.528-5.536V136.853a1.264,1.264,0,0,1,.958-1.226l30.337-7.584a1.264,1.264,0,0,1,1.57,1.226Zm-2.528,9.126v-7.508l-27.809,6.952v7.508l27.809-6.952Zm-35.14,41.182a4.8,4.8,0,1,0-4.8-4.8,4.8,4.8,0,0,0,4.8,4.8Zm30.337-7.584a4.8,4.8,0,1,0-4.8-4.8A4.8,4.8,0,0,0,172.975,171.993Z"
          transform="translate(-130.307 -128.005)"
        />
        <path
          id="large-icon-document"
          d="M13830.391,7830.672a4.171,4.171,0,0,1-4.169-4.166v-9.381h-8.331a3.125,3.125,0,0,1-3.121-3.122v-15.635a3.128,3.128,0,0,1,3.121-3.125h8.331v-14.581a4.177,4.177,0,0,1,4.169-4.176h20.552a1.048,1.048,0,0,1,.748.32l13.791,14.344a1.06,1.06,0,0,1,.288.724v34.635a4.17,4.17,0,0,1-4.169,4.162Zm-2.092-4.166a2.089,2.089,0,0,0,2.092,2.081h31.21a2.087,2.087,0,0,0,2.088-2.078v-33.347h-13.533a1.04,1.04,0,0,1-1.036-1.044v-13.547h-18.729a2.1,2.1,0,0,0-2.092,2.091v14.581h17.7a3.129,3.129,0,0,1,3.122,3.125v3.149h8.8a.981.981,0,1,1,0,1.963h-8.8v5.891h8.8a.983.983,0,1,1,0,1.966h-8.8V7814a3.126,3.126,0,0,1-3.122,3.122h-17.7Zm-11.449-28.138V7814a1.042,1.042,0,0,0,1.041,1.041H13846a1.04,1.04,0,0,0,1.037-1.041v-15.635a1.04,1.04,0,0,0-1.037-1.041h-28.106A1.042,1.042,0,0,0,13816.85,7798.369Zm34.35-7.291h11.317L13851.2,7779.3Zm-16.814,31.058a.981.981,0,1,1,0-1.962h23.538a.981.981,0,1,1,0,1.962Zm4.1-11.546v-8.3h5.68v1.406h-4.009v1.962h3.462v1.406h-3.462v3.525Zm-9.093,0v-8.3h3.059a6,6,0,0,1,1.58.16,2.757,2.757,0,0,1,1.249.766,3.62,3.62,0,0,1,.79,1.343,6.1,6.1,0,0,1,.271,1.962,5.42,5.42,0,0,1-.254,1.765,3.707,3.707,0,0,1-.887,1.465,2.931,2.931,0,0,1-1.177.665,5.143,5.143,0,0,1-1.482.174Zm1.674-1.4h1.249a4.35,4.35,0,0,0,1.013-.08,1.507,1.507,0,0,0,.676-.345,1.709,1.709,0,0,0,.438-.8,5.374,5.374,0,0,0,.17-1.521,4.9,4.9,0,0,0-.17-1.476,1.879,1.879,0,0,0-.474-.8,1.6,1.6,0,0,0-.776-.394,7.728,7.728,0,0,0-1.374-.076h-.752Zm-10.624,1.4v-8.3h2.684a9.694,9.694,0,0,1,1.993.125,2.173,2.173,0,0,1,1.19.814,2.585,2.585,0,0,1,.483,1.614,2.714,2.714,0,0,1-.278,1.284,2.257,2.257,0,0,1-.7.818,2.4,2.4,0,0,1-.87.393,9.483,9.483,0,0,1-1.736.119h-1.09v3.132Zm1.674-4.538h.915a4.3,4.3,0,0,0,1.323-.132,1.081,1.081,0,0,0,.713-1.055,1.083,1.083,0,0,0-.265-.745,1.15,1.15,0,0,0-.675-.369,7.958,7.958,0,0,0-1.2-.056h-.808Z"
          transform="translate(-13814.77 -7775.98)"
        />
        <path
          id="large-icon-files"
          d="M13741.813,8010.669a1.809,1.809,0,0,1-1.81-1.81v-24.57a2.047,2.047,0,0,1,2.045-2.045h1.436v-5.887a1.018,1.018,0,0,1,1.015-1.014h1.962V7972.3a1.015,1.015,0,0,1,1.014-1.014h34.06a1.017,1.017,0,0,1,1.014,1.014v3.039h1.962a1.018,1.018,0,0,1,1.015,1.014v10.572h1.671a1.8,1.8,0,0,1,1.8,1.81v20.12a1.809,1.809,0,0,1-1.81,1.81Zm.724-25.1v21.767a.8.8,0,0,0,.8.8h42.344a.8.8,0,0,0,.8-.8h-.008v-17.082a.8.8,0,0,0-.8-.8h-32.117a.8.8,0,0,1-.7-.419l-2.1-3.848a.8.8,0,0,0-.7-.418h-6.717A.8.8,0,0,0,13742.536,7985.573Zm3.48-3.329h4.8a2.049,2.049,0,0,1,1.8,1.069l1.74,3.2a.8.8,0,0,0,.7.419h27.935v-9.053h-36.974Zm2.977-6.9h31.021v-1.519h-31.021Zm20.625,10.053v-2.533h9.212v2.533Zm-15.32-3.837v-2.53h24.532v2.53Z"
          transform="translate(-13737.004 -7962.582)"
        />
      </svg>
    </div>
  )
}

ChooseMediaType.propTypes = {
  submit: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default ChooseMediaType
