import React, { useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { useDropzone } from "react-dropzone"
import styles from "./file-upload.module.scss"
import { MdBackup } from "react-icons/md"
import { FaTrashAlt } from "react-icons/fa"
import { useField, splitFormProps } from "react-form"
import classNames from "classnames"
import { FaPlus } from "react-icons/fa"
import EXIF from "exif-js"
import util from "../../../../services/util"

const types = [
  "video/mp4",
  "video/webm",
  "video/quicktime",
  "video/avi",
  "video/mpeg",
  "video/ogg",
  "video/x-flv",
  "video/mp4",
  "application/mxf",
  "application/metastream",
  "video/avchd-stream",
  "video/mts",
  "video/vnd.dlna.mpeg-tts",
  "video/MP2T",
  "video/x-ms-asf",
  "application/vnd.ms-asf",
  "video/x-m4v",
  "video/x-matroska",
  "image",
  "application/pdf",
  "application/epub+zip",
  "application/x-mobipocket-ebook",
  "application/rtf",
  "application/postscript",
  "application/ps",
  "application/x-postscript",
  "application/x-ps",
  "text/postscript",
  "application/x-postscript-not-eps",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "audio/midi",
  "audio/x-midi",
  "audio/mp3",
  "audio/mpeg",
  "audio/ogg",
  "audio/opus",
  "audio/flac",
  "audio/wav",
  "audio/qcelp",
  "audio/amr",
  "audio/3gpp",
  "audio/3gpp2",
  "audio/aiff",
  "audio/x-aiff",
  "sound/aiff",
  "audio/rmf",
  "audio/x-rmf",
  "audio/x-pn-aiff",
  "audio/x-gsm",
  "audio/mid",
  "audio/x-midi",
  "audio/vnd.qcelp",
  "audio/ape",
  "audio/wavpack",
  "audio/x-wav",
  "audio/mid",
  "audio/x-mpc",
  "audio/x-musepack",
  "audio/mpc",
  "audio/musepack",
  "audio/x-voc",
  "audio/ac3",
  "audio/mp4a-latm",
  "audio/x-dsf",
  "application/zip",
  "application/x-zip-compressed",
  "application/x-tar",
  "application/x-rar-compressed",
  "application/gzip",
  "application/x-bzip2",
  "application/x-7z-compressed",
  "application/xv+xml",
  "application/x-archive",
  "application/x-compress",
  "application/x-compressed",
  "application/lzip",
]

const accept = {
  Archive:
    "application/zip,application/x-zip-compressed,application/x-tar,application/x-rar-compressed,application/gzip,application/x-bzip2,application/x-7z-compressed,application/xv+xml,application/x-archive,application/x-compress,application/x-compressed,application/lzip",
  Audio:
    "audio/midi,audio/x-midi,audio/mp3,audio/mpeg,audio/ogg,audio/opus,audio/flac,audio/wav,audio/qcelp,audio/amr,audio/3gpp,audio/3gpp2,audio/aiff,audio/x-aiff,sound/aiff,audio/rmf,audio/x-rmf,audio/x-pn-aiff,audio/x-gsm,audio/mid,audio/x-midi,audio/vnd.qcelp,audio/ape,audio/wavpack,audio/x-wav,audio/mid,audio/x-mpc,audio/x-musepack,audio/mpc,audio/musepack,audio/x-voc,audio/ac3,audio/mp4a-latm,audio/x-dsf",
  Document:
    "application/pdf,application/epub+zip,application/x-mobipocket-ebook,application/rtf,application/postscript,application/ps,application/x-postscript,application/x-ps,text/postscript,application/x-postscript-not-eps,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text",
  Photo: "image/*",
  Video:
    "video/mp4,video/webm,video/quicktime,video/avi,video/mpeg,video/ogg,video/x-flv,video/mp4,application/mxf,application/metastream,video/avchd-stream,video/mts,video/vnd.dlna.mpeg-tts,video/MP2T,video/x-ms-asf,application/vnd.ms-asf,video/x-m4v,video/x-matroska",
}
const mediaAccept = `${accept.Archive},${accept.Audio},${accept.Document},${accept.Photo},${accept.Video}`

// const baseUrl =
//   process.env.GATSBY_FEDIA_DMS_PORT &&
//   process.env.GATSBY_FEDIA_DMS_PORT !== "80" &&
//   process.env.GATSBY_FEDIA_DMS_PORT !== 80
//     ? `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/`
//     : `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`

// const fixUrls = media => {
//   return {
//     ...media,
//     webViewUrl: media.webViewUrl
//       ? `${baseUrl}${media.webViewUrl.replace(
//           "http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/",
//           ""
//         )}`
//       : media.webViewUrl,
//     thumbnailUrl: media.thumbnailUrl
//       ? `${baseUrl}${media.thumbnailUrl.replace(
//           "http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/",
//           ""
//         )}`
//       : media.thumbnailUrl,
//   }
// }

const FileUpload = props => {
  // Let's use splitFormProps to get form-specific props
  const [
    field,
    fieldOptions,
    { id, mediaType, mandatory, format, onFileChange, fnLocation, callSetLocation },
  ] = splitFormProps(props)

  // Use the useField hook with a field and field options
  // to access field state
  const {
    value = [],
    setValue,
    meta: { error, isTouched },
  } = useField(field, fieldOptions)

  const [uploadingMedia, setUploadingMedia] = useState(false)

  const [uploadErrorMessage, setUploadErrorMessage] = useState("")

  const onDrop = async acceptedFiles => {
    const formData = new FormData()

    for (let i = 0; i < acceptedFiles.length; i++) {
      const f = acceptedFiles[i]
      console.log("appending:")
      console.log("ff", f)

      let lat = ''
      let latRef = ''
      let lon = ''
      let lonRef = ''

      const exif = EXIF.getData(f, function () {
        let exifData = EXIF.getAllTags(this);
        console.log('exifData:', exifData)
        // console.log('Exif:', exifData.GPSLatitude[0].numerator)
        if (exifData.GPSLatitude && exifData.GPSLongitude) {
          if (exifData.GPSLatitude[0].numerator !== 0) {
            lat = exifData.GPSLatitude[0] + exifData.GPSLatitude[1]/60 + exifData.GPSLatitude[2]/3600
            latRef = exifData.GPSLatitudeRef
            lon = exifData.GPSLongitude[0] + exifData.GPSLongitude[1]/60 + exifData.GPSLongitude[2]/3600
            lonRef = exifData.GPSLongitudeRef
          }  
        }
        
        let name = ''
        // if (lat !== '') {
        //   name = 'Media has location coordinates...' 
        // }
        // else {
        //   name = 'Enter Location...'
        // }

        const location = {
          name,
          lat,
          lon,
        }

        if (callSetLocation) {
          fnLocation(location)
        }

      }) 

      const isAcceptedType = _.includes(types, f.type)

      if (f.type.includes("image")) {
        console.log('Size:', f.size)
        console.log('Max Size:',  process.env.IMAGE_MAX_SIZE)
        if (f.size > process.env.IMAGE_MAX_SIZE && f.type.includes("image")) {
          alert("Image file size should not more then 2 GigaBytes.")
          return
        }
      } else {
        if (isAcceptedType) {
          if (f.size > process.env.VIDEO_MAX_SIZE && f.type.includes("video")) {
            alert("video file size should not more then 2 GigaBytes.")
            return
          }

          if (
            f.size > process.env.PDF_MAX_SIZE &&
            f.type.includes("application/pdf")
          ) {
            alert("PDF file size should not more then 2 GigaBytes.")
            return
          }
        } else {
          alert("Not an Accepted file.")
          return
        }
      }

      // if (f.size > 2000000000  && f.type === "video/mp4" ) {
      //   alert('video file size should not more then 2 GigaBytes.')
      //   return;
      // }

      // else if(f.size > 100000000 && f.type === "application/pdf"){
      //      alert('Pdf file size should not more than 100 Megabytes.')
      //      return;
      //   }
      //  if(f.size > 20000000 && (f.type==='image/gif'|| f.type ==='image/jpeg'||f.type === 'image/png'||f.type === 'image/tif')) {
      //       alert('Image file size should not more then 20 MegaBytes.')
      //       return;
      //    }
      console.log('Files:', f)
      formData.append(field + "-files", f, f.name)
    }

    setUploadErrorMessage("")
    setUploadingMedia(true)
    const uploadedFiles = await axios.post("uploadMedia", formData).then(
      function(uploadedFiles) {
        setUploadingMedia(false)
        const files = _.map(uploadedFiles.data, m => util.fixUrls(m))
        if (onFileChange) {
          onFileChange(files)
        } else {
          _.forEach(files, function(singleMediaFiles) {
            setValue(f => [...f, singleMediaFiles])
          })
        }
      },
      function(error) {
        setUploadingMedia(false)
        setUploadErrorMessage(error.toString())
      }
    )
  }

  const removeFile = i => {
    setValue(value.filter((v, j) => j !== i))
  }

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    accept: accept[mediaType],
    noClick: true,
    noKeyboard: true,
    multiple: true,
    //minSize: 0,
    // maxSize: 20000000, // TODO
  })

  const progressDisplay =
    format === "compact" ? (
      <>{uploadingMedia ? <div>Uploading...</div> : ""}</>
    ) : (
      <>{uploadingMedia ? <div>Uploading Media, Please Wait...</div> : ""}</>
    )

  const errorDisplay = (
    <>
      {uploadErrorMessage && uploadErrorMessage.length > 0 ? (
        format === "compact" ? (
          <div>Upload Error</div>
        ) : (
          <div>
            <div>An error has occured during upload. Please try again.</div>
            <div>{uploadErrorMessage}</div>
          </div>
        )
      ) : (
        ""
      )}
    </>
  )

  const filesDisplay = (
    <ul>
      {value.map((file, i) =>
        file !== null ? (
          <li key={file.id}>
            {file.name} <small> {file.size} bytes</small>
            <button
              type="button"
              id={file.id + "_remove"}
              onClick={() => {
                removeFile(i)
              }}
            >
              <FaTrashAlt />
            </button>
          </li>
        ) : (
          ""
        )
      )}
    </ul>
  )

  if (format === "compact") {
    return (
      <li key="addMedia" onClick={open} className={styles.addMedia}>
        <div
          {...getRootProps({
            className: classNames(styles.dropzone, {
              [styles.active]: isDragActive,
              [styles.error]: error && isTouched,
            }),
          })}
        >
          <input id={id ? id : field} {...getInputProps()} />
          <FaPlus className={styles.addIcon} />
          {progressDisplay}
          {errorDisplay}
        </div>
      </li>
    )
  } else {
    return (
      <>
        <div className={styles.uploadMedia}>
          <div
            {...getRootProps({
              className: classNames(styles.dropzone, {
                [styles.active]: isDragActive,
                [styles.error]: error && isTouched,
              }),
            })}
          >
            <input id={id ? id : field} {...getInputProps()} />
            <MdBackup className={styles.uploadIcon} />
            {util.isMobile() ? 
            (
              <h5>
                <a href="#" onClick={open}>
                  Browse
                </a>{" "}
                to choose files
                {mandatory ? <span className={styles.star}> * </span> : ""}
              </h5>
            ) : (
              <div>
                <h2>Drag and Drop</h2>
                <h5>
                  or{" "}
                  <a href="#" onClick={open}>
                    browse
                  </a>{" "}
                  to choose files
                  {mandatory ? <span className={styles.star}> * </span> : ""}
                </h5>
              </div>
            )}  
          </div>
          {progressDisplay}
          {errorDisplay}
          <aside>{filesDisplay}</aside>
        </div>
      </>
    )
  }
}

FileUpload.defaultProps = {
  format: "full",
}

FileUpload.propTypes = {
  mediaType: PropTypes.string,
  field: PropTypes.string,
  mandatory: PropTypes.bool,
  format: PropTypes.string,
  onFileChange: PropTypes.func,
}

export default FileUpload
